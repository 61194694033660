import React from 'react';
import ClassNames from 'classnames';
import CloseIcon from '@mui/icons-material/Close';

import styles from './EdDialog.module.css';
import EdBadge from '../EdBadge';

interface IEdDialog {
  children: React.ReactNode;
  title: string;
  icon: string;
  open: boolean;
  openEdMicrophonePermission: boolean;
  handleTextAlertMicrphonePermission: () => void;
  onClose?: (status: { open: boolean }) => void;
}

function EdDialog({ children, title, openEdMicrophonePermission, icon, open, handleTextAlertMicrphonePermission, onClose = () => null }: IEdDialog) {
  const [openDialog, setOpenDialog] = React.useState(open);

  React.useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  const handleOnClose = () => {
    if (openEdMicrophonePermission) {
      return handleTextAlertMicrphonePermission();
    }
    setOpenDialog(value => {
      onClose({ open: !value });
      return !value;
    });
  };

  return (
    <div className={ClassNames(styles.root, {
      [styles.open]: openDialog
    })}>
      <div className={styles.paper}>
        <div className={styles.header}>
          <EdBadge icon={icon} label={title} color="secondary" />
          <div className={styles.closeIconContainer} onClick={handleOnClose}>
            <CloseIcon className={styles.closeIcon} />
          </div>
        </div>
        {children}
      </div>
    </div>
  );
}

export default React.memo(EdDialog);
