import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import ClassNames from 'classnames';

import EdProgress from '../EdProgress';
import EdButton from '../EdButton';
import EdFeedbackAlert from '../EdFeedbackAlert';
import { ReactComponent as RefreshIcon } from '../../img/RefreshIcon.svg';


import styles from './TypeTheWords.module.css';

interface TypeTheWordsProps {
  sentence: string;
  onContinue: any;
  onAnswer: any;
}

interface InputProps {
  onChangeInputWord: (event: any) => void;
  name: number;
  length: number;
  word: string;
}

const regex = /\*([^\\*]+?)\*/gmi;

function TypeTheWords({ sentence, onAnswer }: TypeTheWordsProps) {
  const [wordsToComplete, setWordsToComplete] = React.useState<string[]>([]);
  const [valueInputs, setValueInputs] = React.useState<any>({});
  const [done, setDone] = React.useState<boolean | null>(null);
  const [percent, setPercent] = React.useState<number | null>(null);
  const [checked, setChecked] = React.useState(false);
  const [focus, setFocus] = React.useState('0');

  function Input({ onChangeInputWord, name, length, word }: InputProps) {
    return (
      <input
        className={ClassNames({
          [styles.txfWord]: true,
          [styles.txfWordFail]: (done === false && word !== valueInputs[name]),
          [styles.txfWordSuccess]: (done === true && word === valueInputs[name])
        })}
        autoComplete="off"
        type="text"
        maxLength={length}
        size={length - 1}
        name={`${name}`}
        value={valueInputs[name]}
        onChange={onChangeInputWord}
        autoFocus={`${name}` === focus}
        disabled = {done !== null}
      />
    );
  }
  const handleReset = () => {
    const wordInputs: any = {};
    wordsToComplete.forEach((word, index) => {
      wordInputs[index] = '';
    });
    setValueInputs(wordInputs);
    setDone(null);
    setPercent(null);
    setChecked(false);
    setFocus('0');
  };

  React.useEffect(() => {
    let aux;
    const words: string[] = [];
    let inputNumber = 0;
    const wordInputs: any = {};
    while ((aux = regex.exec(sentence)) !== null) {
      words.push(aux[1].toLowerCase());
      const name = inputNumber;
      wordInputs[name] = '';
      inputNumber++;
    }
    setValueInputs(wordInputs);
    setWordsToComplete(words);
  }, [sentence]);

  React.useEffect(() => {
    const isValid = Object.values(valueInputs).some(value => value != '');
    setChecked(isValid);
  }, [valueInputs]);

  const handleCheck = () => {
    const checks: boolean[] = [];
    wordsToComplete.forEach((word, index) => {
      checks.push(word === valueInputs[index]);
    });
    const totalTrues = checks.filter(check => check === true);
    setPercent(totalTrues.length * 100 / checks.length);
    setDone(!checks.includes(false));
    onAnswer({
      answer: JSON.stringify(valueInputs),
      is_correct: !checks.includes(false)
    });
  };

  const onChangeInputWord = (event: any) => {
    const { name, value } = event.target;
    setValueInputs((values: any) => ({
      ...values,
      [name]: value.toLowerCase()
    }));
    setFocus(name);
    setDone(null);
  };

  const text = React.useMemo(() => {
    let aux;
    const words: string[] = [];
    while ((aux = regex.exec(sentence)) !== null) {
      words.push(aux[1].toLowerCase());
    }
    let inputNumber = 0;
    return sentence.split(regex).map((val, i) => {
      if (words.includes(val.toLowerCase())) {
        const name = inputNumber;
        inputNumber++;
        return (<Input
          key={i}
          length={val.length}
          name={name}
          word={val}
          onChangeInputWord={onChangeInputWord}
        />);
      } else {
        return val;
      }
    });
  }, [sentence, done, valueInputs]);


  return (
    <div className={styles.root}>
      <div className={styles.value}>
        {percent !== null &&
          <EdProgress value={percent} />
        }
      </div>
      <pre className={styles.sentence}>
        {text}
      </pre>
      <div className={styles.result}>
        {done === true && (
          <span>
            <EdFeedbackAlert
              color="success"
              text="Nailed it, keep up the good work."
            />
          </span>
        )}
        {done === false && (
          <span>
            <EdFeedbackAlert
              color="warning"
              text="Oops, we need to practice a bit more."
            />
          </span>
        )}
      </div>
      <div className={styles.actionsContainer}>
        {checked && done !== false && done !== true && percent === null
          ? (<EdButton
            endIcon={<CheckIcon/>}
            variant='contained'
            onClick={handleCheck}
          >
            {done}
            Check
          </EdButton>)
          : checked && (done === null || done === false)
            ? (<EdButton
              startIcon={<RefreshIcon width="20px" height="20px" />} variant="contained" color="secondary"
              onClick={handleReset}
            >
              Try again
            </EdButton>)
            : <></>}
      </div>
    </div>
  );
}

export default React.memo(TypeTheWords);
