import React from 'react';

import EdButton from '../EdButton';

import { ReactComponent as MicIcon } from '../../img/MicrophonePermissionIcon.svg';

import EdFeedbackAlert from '../EdFeedbackAlert';

import styles from './EdMicrophonePermissions.module.css';

interface IEdMicrophonePermission {
  handleOnCloseMicrophonePermission?: any;
  textAlert: string;
}

function EdMicrophonePermission({ handleOnCloseMicrophonePermission, textAlert }: IEdMicrophonePermission) {
  const [microphoneAvaible, setMicrophoneAvaible] = React.useState(false);

  const handdlePermission = () => {
    if (!microphoneAvaible) {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(() => {
          setMicrophoneAvaible(true);

          setTimeout(() => {
            handleOnCloseMicrophonePermission();
          }, 3000);
        })
        .catch(() => {
          setMicrophoneAvaible(false);
        });
    }
  };

  return (
    <div className={styles.root}>
      <EdFeedbackAlert
        text={microphoneAvaible ? 'Mic enabled! Get talking and enjoy interactive activities. Have fun!' : textAlert}
        color={microphoneAvaible ? 'success' : 'info'}
      />
      {!microphoneAvaible ? <EdButton
        variant="contained"
        fullWidth
        color="primary"
        size="large"
        startIcon={<MicIcon />}
        onClick={handdlePermission}
      >
        Enable Microphone
      </EdButton> : null}
    </div>
  );
}

export default React.memo(EdMicrophonePermission);
